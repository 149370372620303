<template>
  <div :class="linkClass">
    <p @click.stop="$emit('on:link-click')" class="pointer"> {{ title }} </p>
  </div>
</template>
<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      linkClass: {
        type: String,
        default: ''
      }
    }
  }
</script>